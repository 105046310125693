import Network from '@/services/network'
import { ref } from 'vue'

export default function handlePayBill() {
	const network = new Network;
	const loading = ref(false)
	
	const fetchPayBillList = (query) => {
		return network.api('get', '/pay-bill' + query);
	}
	
	return {
		fetchPayBillList,
		loading
	}
}